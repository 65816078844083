import React, { useEffect, useState } from 'react';
import { getVideoIdFromAlias, getQumuSources } from '../../services/videoLookupService';
import QumuVideoWidget from './qumu-video-widget';
import VideoJSComponent from '../../components/molecules/videoJSPlayer/VideoJSComponent';
import { ClipLoader } from "react-spinners";
import PropTypes from 'prop-types';
import '../../styles/css/video-player.css';

const VideoPlayerComponent = (props) => {
  const { qumuId, alias } = props.item;
  // Use qumuId from the props as the initial guid.
  const [guid, setGuid] = useState(qumuId);
  const [trackData, setTrackData] = useState(null);
  const [loading, setLoading] = useState(true);

  // First effect: if there's no qumuId (guid) but an alias is provided, resolve it.
  useEffect(() => {
    async function resolveId() {
      if (!qumuId && alias) {
        try {
          const response = await translateAliasToQumuId(alias);
          if (response.length >= 1) {
            setGuid(response[0].qumuId);
          }
        } catch (error) {
          console.error("Error translating alias", error);
        }
      }
    }
    resolveId();
  }, [qumuId, alias]);

  // Second effect: fetch trackData once we have a guid.
  useEffect(() => {
    async function fetchSources() {
      if (guid) {
        try {
          const qumuSources = await getQumuSources(guid);
          setTrackData(qumuSources);
        } catch (error) {
          console.error("Error fetching sources", error);
        } finally {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    }
    fetchSources();
  }, [guid]);

  if (loading) {
    return (
      <div style={{ width: '100%', height: '100%', justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
        <ClipLoader color={"#EB0A1E"} loading={true} />
      </div>)
  }

  return trackData ? <VideoJSComponent {...props} hideCTA={props.item?.hideCTA} trackData={trackData} />
    : guid ? (
      <QumuVideoWidget
        analyticsModule={props.analyticsModule}
        analyticsPage={props.analyticsPage}
        analyticsSection={props.analyticsSection}
        analyticsTag={props.analyticsTag}
        analyticsVehicleSpecs={props.analyticsVehicleSpecs}
        guid={guid}
        hideCTA={props.item.hideCTA}
        pageTitle={props.item.pageTitle}
        width={props.item.width}
        height={props.item.height}
        type={props.playlist ? "playlist" : "playback"}
      />
    ) : null;
};

VideoPlayerComponent.propTypes = {
  analyticsModule: PropTypes.string,
  analyticsPage: PropTypes.string,
  analyticsSection: PropTypes.string,
  analyticsTag: PropTypes.string,
  analyticsVehicleSpecs: PropTypes.object,
  playlist: PropTypes.bool,
  item: PropTypes.shape({
    qumuId: PropTypes.string,
    alias: PropTypes.string,
    pageTitle: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
    hideCTA: PropTypes.bool,
  }),
};

export default React.memo(VideoPlayerComponent);
